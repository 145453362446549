export const AssessmentQuestionEnums = Object.freeze({
  DROPDOWN: 'dropdown',
  RADIO: 'radio',
  TEXT: 'text',
  LARGETEXT: 'largetext',
  CHECKBOX: 'checkbox',
  NUMBER: 'number',
  DATE: 'date',
});

export const UserAssessmentStatusEnum = Object.freeze({
  SKIPPED: 'SKIPPED',
  INCOMPLETE: 'INCOMPLETE',
  COMPLETE: 'COMPLETED',
});
