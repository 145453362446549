import { Spinner } from '@/components/icons';

const Loading = () => {
  return (
    <main className="flex h-full w-screen flex-col">
      <section className="container mx-auto flex h-full w-full items-center justify-center text-secondary-800">
        <Spinner className="h-12 w-12 text-secondary-600" />
      </section>
    </main>
  );
};

export default Loading;
