'use client';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { apiSlice } from './api/apiSlice';
import reducers from './reducers';
import subscriptionApi from './features/subscriptions/subscriptionsApi';

const store = configureStore({
  reducer: reducers,
  // devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;

// Call Refresh token on every reload
const initializeApp = async () => {
  await store.dispatch(
    apiSlice.endpoints.refreshToken.initiate({}, { forceRefetch: true }),
  );
  await store.dispatch(
    apiSlice.endpoints.loadUser.initiate({}, { forceRefetch: true }),
  );

  await store.dispatch(
    subscriptionApi.endpoints.getSubscription.initiate('', {
      forceRefetch: true,
    }),
  );
};
initializeApp();
export default store;
